import { useContext, useEffect, useState } from 'react';
import ContentContext from '../../Contexts/ContentContext';
import GenericListItem from './GenericListItem';
import { useNavigate, useParams } from 'react-router';
import utils from '../../utils';
import ContentRequests from '../../Requests/content-requests';

function GenericList() {
    const { content, setContent } = useContext(ContentContext);
    const navigate = useNavigate();
    let { propPath } = useParams();
    const [lastPropPath, setLastPropPath] = useState(propPath);
    let [filtered, setFiltered] = useState([]);
    if ((!lastPropPath && propPath) || lastPropPath != propPath) {
        setFiltered([]);
        setLastPropPath(propPath);
    }

    let [template, setTemplate] = useState(null);
    const [originIsObject, setOriginIsObject] = useState(false);
    const [originalList, setOriginalList] = useState(null);
    let [nameProp, setNameProp] = useState('');
    useEffect(() => {
        if (!content) return;

        let pathSegments = propPath.split('/');
        if (pathSegments.length < 1) return navigate('/dungeons');
        if (!content[pathSegments[0]]) return navigate('/dungeons');

        setOriginIsObject(utils.isObject(content[propPath]));
        setOriginalList(utils.getArray(content, propPath));

        ContentRequests.getSpecificContent(propPath, (serverData) => {
            if (utils.isObject(serverData)) {
                serverData = Object.keys(serverData).map(key => {
                    serverData[key].Id = key;
                    return serverData[key];
                });
            }
            setOriginalList(serverData);
            let nameProp = 'Name';
            if (serverData.length > 0) {
                let template = serverData[0];
                nameProp = utils.getName(template);
            }
            setNameProp(nameProp)

        });
        ContentRequests.getType(pathSegments[0], (serverTemplate) => {
            setTemplate(serverTemplate);
        })
    }, [content, propPath]);

    if (originalList == null || template == null)
        return (<div className='text-white'>Loading...</div>);

    return (
        <div>
            <div className='max-w-25 flex direction-column'>
                <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="search" onInput={(txt) => {
                        if (content) {
                            if (txt.target.value) setFiltered(originalList.filter(x => x[nameProp]?.toLocaleLowerCase().includes(txt.target.value.toLocaleLowerCase())));
                            else setFiltered(originalList);
                        }
                    }}
                        className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" required />
                </div>
                <button type='button ms-5'
                    className='inline-flex mt-1 ms-2 mb-1 items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
                    onClick={() => {
                        if (template != null) {
                            let newEntity = utils.createCopyFromTemplate(template.properties);
                            let dataEdited = content[propPath];
                            if (originIsObject) {
                                let id = Math.max(...Object.keys(content[propPath]));
                                newEntity.Id = id;
                                dataEdited[id] = newEntity;
                            } else if (Array.isArray(content[propPath])) {
                                let biggestId = -1;
                                originalList.forEach(item => {
                                    if (item.Id != undefined && item.Id > biggestId) biggestId = item.Id;
                                });

                                newEntity.Id = biggestId + 1;
                                newEntity[nameProp] = "New Entry";
                                dataEdited.push(newEntity);
                            }
                            ContentRequests.savePartialContent(propPath, dataEdited, ok => {
                                if (ok) {
                                    alert('Created');
                                    setContent(content);
                                }
                            });
                        }
                    }}
                >
                    Add New
                </button>
            </div>
            <div className='flex flex-wrap justify-center'>
                {content == null ? <div className='text-white'>Loading...</div> :
                    (filtered.length > 0 ? filtered : originalList).map((element, i) =>
                        <GenericListItem key={propPath + '-' + element.Id + '-' + ((new Date()).getUTCMilliseconds() + i)}
                            originalData={element}
                            objectPath={propPath + '/' + (originIsObject ? element.Id : i)}
                            onDelete={(originalData) => {
                                let dataEdited = content[propPath];
                                if (originIsObject) {
                                    delete dataEdited[element.Id];
                                } else {
                                    dataEdited.splice(i, 1);
                                }
                                ContentRequests.savePartialContent(propPath, dataEdited, data => {
                                    if (data) {
                                        alert('Deleted');
                                        setContent(content);
                                    }
                                });
                            }}
                        >
                        </GenericListItem>
                    )
                }
            </div>
        </div>
    );
}

export default GenericList;