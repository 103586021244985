import { useContext, useEffect, useRef, useState } from 'react';
import ContentContext from '../../Contexts/ContentContext';
import { useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import GenericEditor from '../Generics/GenericEditor';
import ContentRequests from '../../Requests/content-requests';
import utils from '../../utils';


function GenericEdit() {
    const { content, setContent } = useContext(ContentContext);
    const navigate = useNavigate();
    const [saveCount, setSaveCount] = useState(0);
    let hasChangesRef = useRef(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const [nameProp, setNameProp] = useState(null);
    let previousPath = searchParams.get('previousPath');
    let propPath = searchParams.get('propPath');

    let [data, setData] = useState(null);
    let [template, setTemplate] = useState(null);
    useEffect(() => {
        if (!content) return;
        if (!previousPath || !propPath) return navigate('/dungeons');

        let pathSegments = propPath.split('/');
        if (pathSegments.length < 1) return navigate('/dungeons');
        if (!content[pathSegments[0]]) return navigate('/dungeons');

        let mainSegment = content[pathSegments[0]];
        let originalData = pathSegments.length > 1 ? mainSegment[pathSegments[1]] : mainSegment;
        setNameProp(utils.getName(originalData))
        ContentRequests.getSpecificContent(propPath, (serverData) => {
            setData(serverData);
        });
        ContentRequests.getType(pathSegments[0], (serverTemplate) => {
            setTemplate(serverTemplate);
        })
    }, [content]);

    if (data == null || template == null)
        return (<div className='text-white'>Loading...</div>);

    return (
        <div>
            <div className="border-b border-gray-200 flex flex-wrap ">
                <div className="mt-2 me-4">
                    <button type='button' className='flex flex-column' onClick={() => {
                        if (hasChangesRef.current && !confirm("Are you sure you want to go back?")) return;

                        return navigate(previousPath);
                    }}>
                        <kbd className="rtl:rotate-180 inline-flex items-center px-2 py-1.5 text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">
                            <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16">
                                <path d="M8.766.566A2 2 0 0 0 6.586 1L1 6.586a2 2 0 0 0 0 2.828L6.586 15A2 2 0 0 0 10 13.586V2.414A2 2 0 0 0 8.766.566Z" />
                            </svg>
                            <span className="sr-only">Arrow key left</span>
                        </kbd>
                        <p className="ml-2 cursor rounded-lg group text-white">Back</p>
                    </button>
                </div>
                <p className='inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg text-white group'>
                    {data[nameProp]}
                </p>
            </div>
            <div className='mt-5'>
                <GenericEditor
                    key={propPath + saveCount}
                    data={data}
                    template={template}
                    escapeProps={[]}
                    hasChangesRef={hasChangesRef}
                    onSave={(newData) => {
                        ContentRequests.savePartialContent(propPath, newData, ok => {
                            if (ok) {
                                Object.assign(data, newData);
                                alert('Saved');
                                setData(newData);
                                setContent(content);
                                setSaveCount(saveCount + 1);
                            }
                        });
                    }}>

                </GenericEditor>
            </div>
        </div>

    );
}

export default GenericEdit;