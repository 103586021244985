import { useState } from 'react';
import logo from '../../logo.svg';
import './Dungeon.css';
import { Link } from 'react-router-dom';

function Dungeon({ dungeonData, onDelete = (data) => { }, onDuplicate = (data) => { } }) {
    let [dungeon, setDungeon] = useState(dungeonData);
    return (
        <div className="max-w-sm min-w-25 margin-1 p-6 bg-white border border-gray-200 rounded-lg shadow">
            <Link to={"/dungeons/dungeon/" + dungeon.Id}>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{dungeon.Name}</h5>
            </Link>
            <p className="mb-3 font-normal text-gray-700">
                Id: {dungeon.Id} <br></br>
                Base XP requirement: {dungeon.BaseRequirementXP} <br></br>
                XP Step: {dungeon.XpStep} <br></br>
                XP XpScale: {dungeon.XpScale} <br></br>
                XP Multiplier: {dungeon.XPGainMultiplier} <br></br>
                Max Player Level: {dungeon.MaxPlayerLevel} <br></br>
                Minimum Gold Gain: {dungeon.DungeonGoldConstant} <br></br>
                Base Gold: {dungeon.DungeonGoldBase} <br></br>
                Gold Modifier: {dungeon.DungeonGoldModifier} <br></br>
                NPC Begin level: {dungeon.NpcBeginLevel} <br></br>
                NPC Level PV: {dungeon.NpcLevelPV} <br></br>
            </p>
            <div className='w-full flex direction-column mt-5 ms-2'>
                <Link to={"/dungeons/dungeon/" + dungeon.Id}
                    className='inline-flex me-3 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300'
                >
                    Edit
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </Link>
                <button type="button" className="rigth flex self-center justify-center items-center max-w-40 w-2/4 h-10 text-center me-3 bg-orange-500 rounded-lg hover:bg-orange-700 focus:ring-4"
                    onClick={() => {
                        if (confirm("Are you sure you want to duplicate this item?")) {
                            onDuplicate(dungeon);
                        }
                    }}>
                    Duplicate
                </button>
                <button type="button" className="rigth flex self-center justify-center items-center max-w-15 w-1/6 h-10 text-center me-3 rounded-lg bg-red-500 hover:bg-red-400"
                    onClick={() => {
                        if (confirm("Are you sure you want to delete this item?")) {
                            onDelete(dungeon);
                        }
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
            </div>
        </div>

    );
}

export default Dungeon;