
import { useContext, useState } from 'react';
import './Login.css';
import UserRequests from '../../Requests/user-requests';
import { useNavigate } from 'react-router';
import ContentContext from '../../Contexts/ContentContext';

function Login() {
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [rememberMe, setRememberMe] = useState(false);
    let [error, setError] = useState(false);
    let navigate = useNavigate();
    let { setContent } = useContext(ContentContext);

    function submitLogin() {
        UserRequests.login(username, password, rememberMe,
            ok => {
                setError(!ok);
                if (ok) {
                    setContent();
                    navigate('/dungeons');
                }
            }
        );
    }

    return (
        <div className="max-w-sm mx-auto h-screen flex justify-center flex-col">
            <p hidden={!error} className="mt-2 text-lg text-center text-red-500 mb-5"><span className="font-medium">Couldn't Login!</span></p>
            <div className="mb-5">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Username</label>
                <input type="email" value={username} onChange={e => setUsername(e.target.value)} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="username" required />
            </div>
            <div className="mb-5">
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Password</label>
                <input type="password" onSubmit={() => submitLogin()} value={password} onChange={e => setPassword(e.target.value)} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="password" required />
            </div>
            <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                    <input id="remember" type="checkbox" value={rememberMe} onChange={e => setRememberMe(e.target.checked)} className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                </div>
                <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-300">Remember me</label>
            </div>
            <button onClick={() => submitLogin()} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
        </div>
    )
}

export default Login;