
import { useContext, useState } from 'react';
import './UserSettings.css';
import UserRequests from '../../Requests/user-requests';

function UserSettings() {
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [error, setError] = useState(false);

    function submitUserChange() {
        if (password != confirmPassword) {
            setError('Password does not match!');
            return;
        }
        UserRequests.changePassword(password,
            data => {
                setError(!data.success ? `Couldn't changed the password!` : undefined);
                if (data.success) {
                    alert('Password Chnged!');
                }
            }
        );
    }

    return (
        <div className="max-w-sm mx-auto h-screen flex justify-center flex-col">
            <p hidden={!error} className="mt-2 text-lg text-center text-red-500 mb-5"><span className="font-medium">{error}</span></p>
            <div className="mb-5">
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Password</label>
                <input type="password" onSubmit={() => submitUserChange()} value={password} onChange={e => setPassword(e.target.value)} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="password" required />
            </div>
            <div className="mb-5">
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Confirm Password</label>
                <input type="password" onSubmit={() => submitUserChange()} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="password" required />
            </div>
            <button onClick={() => submitUserChange()} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
        </div>
    )
}

export default UserSettings;