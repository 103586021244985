import { useContext, useEffect, useState } from 'react';
import './MigrateEnvironment.css';
import constants from '../../constants';
import ContentContext from '../../Contexts/ContentContext';
import UserRequests from '../../Requests/user-requests';

function MigrateEnvironment() {
    let [source, setSource] = useState(constants.DEVELOPMENT_HOST);
    let [targetUsername, setTargetUsername] = useState("");
    let [targetPassword, setTargetPassword] = useState("");
    let [targetOk, setTargetOk] = useState(null);
    let [target, setTarget] = useState(constants.PRODUCTION_HOST);
    let [error, setError] = useState(false);
    let [selectedSegments, setSelectedSegments] = useState([]);
    let [reloadCount, setReloadCount] = useState(0);
    let [content, setContent] = useState(null);

    useEffect(() => {
        fetch(source + constants.CONTENT_PATH).then(async (res) => {
            if (!res.ok) {
                alert("Couldn't get content from Source");
                return;
            }
            content = await res.json();
            setContent(content);
            setReloadCount(reloadCount++);
            setReloadCount(reloadCount++);
        });
    }, [source]);
    async function submitMigration() {
        let sourceContentRequest = await fetch(source + constants.CONTENT_PATH);
        if (!sourceContentRequest.ok) {
            alert("Couldn't get content from Source");
            return;
        }
        let sourceContent = await sourceContentRequest.json();

        let targetToken = UserRequests.accessToken;
        if (target != constants.Environment.host) {
            let res = await fetch(target + constants.LOGIN_PATH, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                        username: targetUsername,
                        password: targetPassword
                    }
                )
            });

            if (!res.ok) {
                setTargetOk(false);
                alert("Target Username/Password not valid");
                return;
            }
            var result = await res.json();
            targetToken = (result.token);
        }


        let targetContentRequest = await fetch(target + constants.CONTENT_PATH);
        if (!sourceContentRequest.ok) {
            alert("Couldn't get content from Target");
            return;
        }
        let targetContent = await targetContentRequest.json();

        selectedSegments.forEach(x => {
            if (targetContent[x]) {
                targetContent[x] = sourceContent[x];
            }
        });

        console.log(targetContent);
        fetch(target + constants.CONTENT_PATH, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Token: targetToken
            },
            body: JSON.stringify(targetContent)
        })
            .then(async res => {
                if (res.ok) {
                    alert("Update Successful");
                } else {
                    alert("Update Failed: " + await res.json());
                }
            })
            .catch(err => {
                console.error(err);
                alert("You do not have permisions to save content");
                callback(false);
            });
    }

    async function testCredentials(env, username, password) {
        debugger;
        if (env == constants.Environment.host) {
            return true;
        }
        else {
            let res = await fetch(constants.Environment.host + constants.LOGIN_PATH, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                        username: username,
                        password: password
                    }
                )
            })

            return res.ok;
        }
    }

    return (
        <div style={{ height: "95%" }}>
            <div className="w-full flex flex-auto flex-row gap-x-32">
                <p hidden={!error} className="mt-2 text-lg text-center text-red-500 mb-5"><span className="font-medium">{error}</span></p>
                <div className="justify-center flex-col max-w-25 grow">
                    <h3 className='text-white text-lg font-bold'>Source Environment</h3>
                    <div className="mb-5">
                        <label htmlFor="sourceEnvironment" className="block text-sm font-medium leading-6 text-white">
                            Environment
                        </label>
                        <select
                            id="sourceEnvironment"
                            name="sourceEnvironment"
                            autoComplete="country-name"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={e => {
                                setSource(e.target.value);
                            }}
                            value={source}
                        >
                            <option value={"http://localhost:8000"}>Local</option>
                            <option value={constants.DEVELOPMENT_HOST}>Development</option>
                            <option value={constants.PRODUCTION_HOST}>Production</option>
                        </select>
                    </div>
                </div>
                <div className='content-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="96" height="96" color="#ffff" fill="none">
                        <path d="M14 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.5859 13.6026L17.6194 14.3639C16.0536 15.5974 15.2707 16.2141 14.6354 15.9328C14 15.6515 14 14.6881 14 12.7613L14 11.2387C14 9.31191 14 8.34853 14.6354 8.06721C15.2707 7.7859 16.0536 8.40264 17.6194 9.63612L18.5858 10.3974C19.5286 11.1401 20 11.5115 20 12C20 12.4885 19.5286 12.8599 18.5859 13.6026Z"
                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="justify-center flex-col max-w-25 grow">
                    <h3 className='text-white text-lg font-bold'>Target Environment</h3>
                    <div className="mb-5">
                        <label htmlFor="sourceEnvironment" className="block text-sm font-medium leading-6 text-white">
                            Environment
                        </label>
                        <select
                            id="sourceEnvironment"
                            name="sourceEnvironment"
                            autoComplete="country-name"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={e => {
                                setTarget(e.target.value);
                            }}
                            value={target}
                        >
                            <option value={"http://localhost:8000"}>Local</option>
                            <option value={constants.DEVELOPMENT_HOST}>Development</option>
                            <option value={constants.PRODUCTION_HOST}>Production</option>
                        </select>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-white">Target Username</label>
                        <input type='text' value={targetUsername} onChange={e => {
                            setTargetOk(null);
                            setTargetUsername(e.target.value)
                        }} id="username" className={`${targetOk ? "border-green-600 border-2" : targetOk == false ? "border-red-600 border-2" : ""} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="Username" required />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Target Password</label>
                        <input type="password" value={targetPassword} onChange={e => {
                            setTargetOk(null);
                            setTargetPassword(e.target.value)
                        }} id="password" className={`${targetOk ? "border-green-600 border-2" : targetOk == false ? "border-red-600 border-2" : ""} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} placeholder="Password" required />
                    </div>
                    <button onClick={async () => {
                        setTargetOk(await testCredentials(target, targetUsername, targetPassword));
                    }} className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2 text-center">Test Credentials</button>
                </div>
                <button
                    className="text-white grow max-w-6 my-28 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 text-center"
                    onClick={() => {
                        if (confirm(`Are you Sure you Want To Migrate from ${source} to ${target}`)) {
                            if (confirm(`Are you EXTREMELY SURE you want to Migrate from ${source} to ${target}`)) {
                                submitMigration();
                            }
                        }

                    }}>Migrate</button>
            </div>
            <div className="mt-5 w-full h-2/3 p-6 bg-white border border-gray-200 rounded-lg shadow overflow-auto flex flex-col">
                <div className='flex mb-2'>
                    <input className='me-2' id={`selectAll`} type='checkbox' onChange={e => {
                        if (e.target.checked) {
                            selectedSegments = Object.keys(content);
                            setSelectedSegments(selectedSegments);
                        }
                        else {
                            setSelectedSegments([]);
                        }
                        // THIS IS MAGIC, OTHERWISE CHECKBOXES DON'T WORK
                        setReloadCount(reloadCount++);
                        setReloadCount(reloadCount++);
                    }} />
                    <label htmlFor={`selectAll`} className="block text-sm font-medium leading-6 text-gray-900">
                        <b>Select All</b>
                    </label>
                </div>
                {content == null ? "" : Object.keys(content).map(key => {
                    if (key.toLocaleLowerCase() == 'available_assets') return "";
                    return (
                        <div className='flex' key={`section-${key}`}>
                            <input className='me-2' id={`section-${key}`} type='checkbox' checked={selectedSegments.includes(key)} onChange={e => {
                                if (e.target.checked) {
                                    selectedSegments.push(key);
                                    setSelectedSegments(selectedSegments);
                                }
                                else {
                                    let index = selectedSegments.indexOf(key);
                                    if (index != -1) {
                                        selectedSegments.splice(index, 1);
                                        setSelectedSegments(selectedSegments);
                                    }
                                }
                                // THIS IS MAGIC, OTHERWISE CHECKBOXES DON'T WORK
                                setReloadCount(reloadCount++);
                                setReloadCount(reloadCount++);
                            }} />
                            <label htmlFor={`section-${key}`} className="block text-sm font-medium leading-6 text-gray-900">
                                {key}
                            </label>
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}


export default MigrateEnvironment;
