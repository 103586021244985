import { useContext, useEffect, useRef, useState } from 'react';
import './DungeonRoomEdit.css';
import ContentContext from '../../Contexts/ContentContext';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ContentRequests from '../../Requests/content-requests';
import DungeonTileRenderer from './DungeonRoomTileRender';

const RoomTemplate = { Tiles: [], IsBossRoom: false, IsFinalBossRoom: false, IsRewardRoom: false, ForceRoomLevels: [] }
export { RoomTemplate }

const TileTemplate = { TileType: 0, PotentialEnemyTypes: [], PotentialEnemies:[], EnemyLevel: 1, X: 0, Y: 0, Z: 0 };
export { TileTemplate }

const CreateEmptyRoom = () => {
    let entity = { ...RoomTemplate };
    entity.Tiles = [];
    for (let xI = 0; xI < 11; xI++) {
        for (let yI = 0; yI < 20; yI++) {
            let tile = { ...TileTemplate };
            tile.X = xI;
            tile.Y = yI;
            entity.Tiles.push(tile);
        }
    }
    return entity;
}

export { CreateEmptyRoom }

function DungeonRoomEdit({ roomData, roomName, roomIndex, onSave = () => { } }) {
    const { content } = useContext(ContentContext);
    let [tempData, setTempData] = useState(() => JSON.parse(JSON.stringify(roomData)));
    let [selectedTile, setSelectedTile] = useState(null);
    let [forceRefresh, setForceRefresh] = useState(0);

    useEffect(() => {
        let handler = (e) => {
            if (selectedTile) {
                switch (e.key) {
                    case 'q':
                        selectedTile.TileType = 0;
                        break;
                    case 'w':
                        selectedTile.TileType = 1;
                        break;
                    case 'e':
                        selectedTile.TileType = 2;
                        break;
                }

                setForceRefresh((x) => x + 1);
            }
        }
        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
        }
    }, [selectedTile]);

    return (
        <div
            className="w-full h-full flex flex-col p-3 bg-white border border-gray-200 rounded-lg shadow">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{roomName}</h5>

            <div className='h-5/6 flex justify-start'>
                <div className='w-3/6 ms-5 me-5'>
                    <DungeonTileRenderer
                        tiles={tempData.Tiles}
                        canEdit={true}
                        onTileSelect={setSelectedTile}
                        forceRefresh={forceRefresh}
                    >
                    </DungeonTileRenderer>
                </div>

                <div className='flex flex-col grow text-left overflow-y-auto' >
                    <h6 className='text-lg font-bold mb-2 text-left'>Room Options</h6>
                    <div className='flex flex-col mb-2'>
                        <label>Must Appear at Room Index
                            <button type="button" className="w-5 p-1 ms-2 rounded-full bg-green-600 hover:bg-green-500"
                                onClick={() => {
                                    tempData.ForceRoomLevels.push(1);
                                    setForceRefresh((x) => x + 1);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                            </button>
                        </label>
                        {!tempData.ForceRoomLevels ? undefined : tempData.ForceRoomLevels.map((x, i) =>
                            <div key={`forceRoom-${i}`} className='flex flex-row'>
                                <input type='number' className='p-1 grow w-5/6 border border-gray-200 rounded-lg mb-2' value={x}
                                    onChange={(e) => {
                                        tempData.ForceRoomLevels[i] = parseInt(e.target.value, 10);
                                        setForceRefresh((x) => x + 1);
                                    }}>
                                </input>
                                <button type="button" className="ms-2 mb-2 rigth flex self-center justify-center items-center max-w-15 w-1/6 h-8 text-center rounded-lg bg-red-500 hover:bg-red-400"
                                    onClick={() => {
                                        if (confirm("Are you sure you want to delete this item?")) {
                                            tempData.ForceRoomLevels.splice(i, 1);
                                            setForceRefresh((x) => x + 1);
                                        }
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                    <h6 className='text-lg font-bold mb-2 text-left'>Tile Options</h6>
                    {selectedTile == null ? <p>No tile Selected</p> :
                        <>
                            <div className='flex flex-col mb-2'>
                                <label>Type</label>
                                <select
                                    value={selectedTile.TileType}
                                    onChange={(e) => {
                                        selectedTile.TileType = parseInt(e.target.value, 10);
                                        setForceRefresh((x) => x + 1);
                                    }}
                                    className='p-1 grow border border-gray-200 rounded-lg'>
                                    <option value={0}>Floor</option>
                                    <option value={1}>Wall</option>
                                    <option value={2}>Hole</option>
                                </select>
                            </div>
                            <div className='flex flex-col mb-2'>
                                <label>Enemy Level</label>
                                <input type='number' className='mb-2 p-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 ' onChange={(e) => {
                                    selectedTile.EnemyLevel = parseInt(e.target.value, 10);
                                    setForceRefresh((x) => x + 1);
                                }} value={selectedTile.EnemyLevel}/>
                                <div>
                                    <label className='me-12'>Potential Enemies</label>
                                    <label className='ms-1'>Wave</label>
                                </div>
                                {
                                    selectedTile.PotentialEnemies.map((x, i) =>
                                        <div key={`enemy-${i}`} className='flex flex-row'>
                                            <select className='p-1 grow w-5/6 border border-gray-200 rounded-lg mb-2'
                                                value={x.EnemyId}
                                                onChange={(e) => {
                                                    selectedTile.PotentialEnemies[i].EnemyId = parseInt(e.target.value, 10);
                                                    setForceRefresh((x) => x + 1);
                                                }}
                                            >
                                                {content.Units.map(unit =>
                                                    <option key={unit.Id} value={unit.Id}>{unit.Name}</option>
                                                )}
                                            </select>
                                            <input type='number' className='mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 ' onChange={(e) => {
                                                selectedTile.PotentialEnemies[i].WaveIndex = parseInt(e.target.value, 10);
                                                setForceRefresh((x) => x + 1);
                                            }} value={x.WaveIndex}/>
                                            <button type="button" className="ms-2 mb-2 rigth flex self-center justify-center items-center max-w-15 w-1/6 h-8 text-center rounded-lg bg-red-500 hover:bg-red-400"
                                                onClick={() => {
                                                    if (confirm("Are you sure you want to delete this item?")) {
                                                        selectedTile.PotentialEnemies.splice(i, 1);
                                                        setForceRefresh((x) => x + 1);
                                                    }
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </button>
                                        </div>
                                    )
                                }
                                <button type="button" className="w-5 p-1 ms-1 rounded-full bg-green-600 hover:bg-green-500"
                                    onClick={() => {
                                        selectedTile.PotentialEnemies.push({EnemyId:content.Units[0].Id, WaveIndex:1});
                                        setForceRefresh((x) => x + 1);
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                </button>
                            </div>
                            <div style={{ flexGrow: 1 }}>

                            </div>
                            <div className='text-left'>
                                <p className='font-bold'>Instructions</p>
                                <p>Press Q - Floor</p>
                                <p>Press W - Wall</p>
                                <p>Press E - Hole</p>
                            </div>
                        </>
                    }
                </div>
            </div>

            <button type="button" className="mt-5 text-white rigth flex self-center justify-center items-center max-w-40 w-3/4 h-10 text-center me-3 bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4"
                onClick={() => {
                    onSave(tempData);
                }}>
                Save
            </button>
        </div>
    );
}

export default DungeonRoomEdit;