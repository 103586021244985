import utils from "./utils";

const environment = {
    host: process.env.REACT_APP_DEV_HOST,
};
let environmentValue = utils.getCookieValue('environment');
console.log(environmentValue);
if (environmentValue) {
    environment.host = environmentValue;
}
export default {
    Environment: environment,

    //Hosts
    DEVELOPMENT_HOST: process.env.REACT_APP_DEV_HOST,
    PRODUCTION_HOST: process.env.REACT_APP_PROD_HOST,

    //Users
    LOGIN_PATH: '/api/user/login',
    ME_PATH: '/api/user/me',
    PASSWORD_PATH: '/api/user/password',

    //Content
    CONTENT_PATH: '/api/Content',
    CONTENT_TYPE_PATH: '/api/Content/type',
    CONTENT_SAVE_PARTIAL_PATH: '/api/Content/partial',
}