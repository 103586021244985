import { useEffect, useState } from "react";
import './DungeonRoomTileRender.css'

const TileType = {
    Walkable: 0,
    Wall: 1,
    Hole: 2
}
const ColorSchema = {
    [TileType.Walkable]: 'gray',
    [TileType.Hole]: 'blue',
    [TileType.Wall]: 'black'
}

export { TileType }

function DungeonTileRenderer({ tiles = [], canEdit = false, onTileSelect = (tile) => { }, forceRefresh = 0 }) {

    const [buffer, setBuffer] = useState([])
    let [selectedIndex, setSelectedIndex] = useState(-1);
    const [columnCount, setColumnCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    useEffect(() => {
        tiles.sort((a, b) => {
            if (a.Y > b.Y) return -1;
            if (a.Y < b.Y) return 1;

            if (a.X > b.X) return 1;
            else if (a.X < b.X) return -1;
            return 0;
        })

        let tempBuffer = [];
        let maxY = 0;
        let maxX = 0;
        tiles.forEach((x, i) => {
            // if (i > 0 && tiles[i - 1].Y != x.Y) tempBuffer.push(<div key={`line-break-` + i} className="tile-break"></div>);

            if (!x.PotentialEnemyTypes) x.PotentialEnemyTypes = [];

            if (x.Y > maxY) maxY = x.Y;
            if (x.X > maxX) maxX = x.X;

            tempBuffer.push(<div key={`tile-` + i}
                onClick={() => {
                    if (!canEdit) return;

                    setSelectedIndex(i);
                    onTileSelect(x);
                }}
                className={`tile ${i == selectedIndex ? 'tile-selected' : ''} ${canEdit ? 'cursor-pointer hover:border-slate-100 hover:border-dashed' : ''}`}
                style={{ backgroundColor: ColorSchema[x.TileType] }}>
                {x.PotentialEnemies && x.PotentialEnemies.length > 0 ? <div className="tileElement" style={{ backgroundColor: "red" }}></div> : null}
            </div>)
        })
        setColumnCount(maxX + 1);
        setRowCount(maxY + 1);
        setBuffer(tempBuffer);
    }, [tiles, selectedIndex, forceRefresh]);

    useEffect(() => {
        if (!canEdit) return;
        let handler = (e) => {
            if (selectedIndex > -1) {
                switch (e.key) {
                    case 'ArrowLeft':
                        if (selectedIndex - 1 >= 0) selectedIndex -= 1;
                        break;
                    case 'ArrowRight':
                        if (selectedIndex + 1 <= buffer.length - 1) selectedIndex += 1;
                        break;
                    case 'ArrowDown':
                        if (selectedIndex <= buffer.length - columnCount - 1) selectedIndex += columnCount;
                        break;
                    case 'ArrowUp':
                        if (selectedIndex >= columnCount) selectedIndex -= columnCount;
                        break;
                    default: return;
                }
            }
            setSelectedIndex(selectedIndex);
            onTileSelect(tiles[selectedIndex]);
        }
        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
        }
    }, [selectedIndex, columnCount, tiles]);


    return (
        <div className="tile-container w-full h-full justify-center bg-white" style={{
            gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
            gridTemplateRows: `repeat(${rowCount}, 1fr)`
        }}>
            {buffer}
        </div>
    );
}

export default DungeonTileRenderer;