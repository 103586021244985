import { useContext } from "react";
import constants from "../constants";
import UserRequests from "./user-requests";

const ContentRequests = {
    getContent(callback = (data) => { }) {
        fetch(constants.Environment.host + constants.CONTENT_PATH)
            .then(async res => {
                callback(await res.json());
            });
    },
    getSpecificContent(contentPath, callback = (data) => { }) {
        fetch(constants.Environment.host + constants.CONTENT_PATH + `?contentPath=${contentPath}`)
            .then(async res => {
                callback(await res.json());
            });
    },
    saveContent(data, callback = ok => { }) {
        fetch(constants.Environment.host + constants.CONTENT_PATH, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Token: UserRequests.accessToken
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                callback(res.ok);
            })
            .catch(err => {
                console.error(err);
                alert("You do not have permisions to save content");
                callback(false);
            });
    },
    savePartialContent(contentPath, data, callback = data => { }) {
        fetch(constants.Environment.host + constants.CONTENT_SAVE_PARTIAL_PATH + `?contentPath=${contentPath}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Token: UserRequests.accessToken
            },
            body: JSON.stringify(data)
        })
            .then(async res => {
                callback(await res.json());
            })
            .catch(err => {
                console.error(err);
                callback(err);
            });
    },
    getType(contentPath, callback = data => { }) {
        fetch(constants.Environment.host + constants.CONTENT_TYPE_PATH + `?contentPath=${contentPath}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Token: UserRequests.accessToken
            }
        })
            .then(async res => {
                callback(await res.json());
            })
            .catch(err => {
                console.error(err);
                callback(err);
            });
    }
}

export default ContentRequests