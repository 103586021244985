import { useContext } from "react";
import constants from "../constants";
import utils from "../utils";

const UserRequests = {
    accessToken: null,
    lastTokenCheck: null,
    isTokenValid() {
        if (this.accessToken == null
            || !utils.getCookieValue('access_token')) return false;
        if (this.lastTokenCheck == null
            || (new Date()).getUTCMilliseconds() - this.lastTokenCheck > 1000 * 60 * 5) return false;
    },
    updateLastTokenCheck() {
        this.lastTokenCheck = (new Date()).getUTCMilliseconds();
    },
    login(username, password, rememberMe = false, callback = ok => { }) {
        fetch(constants.Environment.host + constants.LOGIN_PATH, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                    username: username,
                    password: password
                }
            )
        })
            .then(async res => {
                if (res.ok) {
                    var result = await res.json();
                    this.accessToken = (result.token);
                    if (rememberMe) {
                        document.cookie = `access_token=${this.accessToken};Path=/`;
                    }
                    this.updateLastTokenCheck();
                }
                callback(res.ok);
            });
    },
    me(callback = (data) => { }) {
        fetch(constants.Environment.host + constants.ME_PATH, {
            headers: {
                Token: this.accessToken
            }
        })
            .then(async res => {
                let data = await res.json();
                if (data) this.updateLastTokenCheck();
                callback(data);
            })
            .catch(err => {
                console.error(err);
                callback(null);
            });
    },
    changePassword(password, callback = (data) => { }) {
        fetch(constants.Environment.host + constants.PASSWORD_PATH, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Token: this.accessToken
            },
            body: JSON.stringify(
                {
                    password: password
                }
            )
        })
            .then(async res => {
                if (res.ok) {
                    callback(await res.json());
                    return;
                }
                callback({ success: false })
            });
    }
}

export default UserRequests