import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { useEffect, useState } from 'react';
import Layout from './Layout';
import Dungeons from "./Components/Dungeons/Dungeons";
import constants from "./constants";
import ContentContext from "./Contexts/ContentContext";
import DungeonEdit from "./Components/DungeonEdit/DungeonEdit";
import ContentRequests from "./Requests/content-requests";
import Login from "./Components/Login/Login";
import GenericList from "./Components/Generics/GenericList";
import GenericEdit from "./Components/Generics/GenericEdit";
import UserSettings from "./Components/User/UserSettings";
import MigrateEnvironment from "./Components/Environment/MigrateEnvironment";

function App() {
  const [content, setContent] = useState(null);
  useEffect(() => {
    ContentRequests.getContent(data => setContent(data));
  }, []);

  return (
    <ContentContext.Provider value={{ content: content, setContent: (data) => { ContentRequests.getContent(data => setContent(data)); } }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dungeons />} />
            <Route path="login" element={<Login />} />
            <Route path="dungeons" element={<Dungeons />} />
            <Route path="dungeons/dungeon/:id" element={<DungeonEdit />} />
            <Route path="genericList/:propPath" element={<GenericList />} />
            <Route path="genericEdit" element={<GenericEdit />} />
            <Route path="userSettings" element={<UserSettings />} />
            <Route path="migrateEnvironment" element={<MigrateEnvironment />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContentContext.Provider>
  );
}

export default App;
