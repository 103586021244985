import { useContext, useEffect, useRef, useState } from 'react';
import './DungeonRoomListItem.css';
import ContentContext from '../../Contexts/ContentContext';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ContentRequests from '../../Requests/content-requests';
import DungeonTileRenderer from './DungeonRoomTileRender';

function DungeonRoomListItem({ dungeonId, roomData, roomIndex, onDelete = (data) => { }, onEdit = (data) => { }, onDuplicate = (data) => { } }) {
    let roomType = 'Standard Room';
    if (roomData.IsBossRoom && roomData.IsFinalBossRoom && !roomData.IsRewardRoom)
        roomType = 'Final Boss Room'
    else if (roomData.IsBossRoom && !roomData.IsFinalBossRoom && !roomData.IsRewardRoom)
        roomType = 'Boss Room'
    else if (!roomData.IsBossRoom && !roomData.IsFinalBossRoom && roomData.IsRewardRoom)
        roomType = 'Reward Room'
    else if (!roomData.IsBossRoom && roomData.IsFinalBossRoom && !roomData.IsRewardRoom)
        roomType = 'Final Boss Room'

    return (
        <div className="w-1/6 min-w-15 h-96 margin-1 p-2 bg-white border border-gray-200 rounded-lg shadow">
            <h5 className="mb-2 cursor-pointer text-2xl font-bold tracking-tight text-gray-900"
                onClick={() => {
                    onEdit(roomData);
                }}
            > {roomType} {roomIndex + 1}</h5 >
            <div className='m-auto h-4/6 w-5/6'>
                <DungeonTileRenderer
                    tiles={roomData.Tiles}
                    canEdit={false}
                >
                </DungeonTileRenderer>
            </div>
            <div className='flex direction-column mt-5 ms-2'>
                <button type="button" className="rigth flex self-center justify-center items-center max-w-15 w-1/6 h-10 text-center me-3 rounded-lg bg-red-500 hover:bg-red-400"
                    onClick={() => {
                        if (confirm("Are you sure you want to delete this item?")) {
                            onDelete(roomData);
                        }
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-4 block">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
                <button type="button" className="rigth flex self-center justify-center items-center max-w-40 w-2/4 h-10 text-center me-3 bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4"
                    onClick={() => {
                        onEdit(roomData);
                    }}>
                    Edit
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </button>
                <button type="button" className="rigth flex self-center justify-center items-center max-w-40 w-2/4 h-10 text-center me-3 bg-orange-500 rounded-lg hover:bg-orange-700 focus:ring-4"
                    onClick={() => {
                        if (confirm("Are you sure you want to duplicate this item?")) {
                            onDuplicate(roomData);
                        }
                    }}>
                    Duplicate
                </button>
            </div>
        </div >
    );
}

export default DungeonRoomListItem;