import { useContext, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import UserRequests from "./Requests/user-requests";
import ContentContext from "./Contexts/ContentContext";
import constants from "./constants";
import ContentRequests from "./Requests/content-requests";
import utils from "./utils";

function Layout() {
    let navigate = useNavigate();
    let path = window.location.pathname;
    if (path != '/login') {
        if (!UserRequests.isTokenValid()) {
            if (!UserRequests.accessToken)
                UserRequests.accessToken = utils.getCookieValue('access_token');
        }
        if (!UserRequests.accessToken)
            return navigate('login');

        if (!UserRequests.isTokenValid()) {
            UserRequests.me(data => {
                if (!data) {
                    UserRequests.accessToken = null;
                    return navigate('login');
                }
            });
        }
    }
    let { content, setContent } = useContext(ContentContext);

    return (
        <>
            <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
                    <div className="flex items-center ps-2.5 mb-5">
                        <img src="https://flowbite.com/docs/images/logo.svg" className="h-6 me-3 sm:h-7" alt="Flowbite Logo" />
                        <Link className="self-center text-xl font-semibold whitespace-nowrap" to="/">Swordero CMS</Link>
                    </div>
                    <ul className="space-y-2 font-medium" style={{ maxHeight: '87%', overflowY: 'scroll' }}>
                        <li>
                            <Link to="/dungeons" className="flex items-center p-2 text-gray-900 rounded-lggroup">
                                <svg className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <p className="ms-3">Dungeons</p>
                            </Link>
                        </li>
                        {content == null ? "" : Object.keys(content).map(key => {
                            if (key.toLocaleLowerCase() == 'dungeons'
                                || key.toLocaleLowerCase() == 'available_assets'
                                || key.toLocaleLowerCase() == 'constants') return "";

                            return (<li key={key}>
                                <Link to={`/genericList/${key}`} className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                                    <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                        <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                                    </svg>
                                    <p className="flex-1 ms-3 whitespace-nowrap">{key}</p>
                                </Link>
                            </li>)
                        })}
                        <li>
                            <Link to={`/genericEdit?propPath=Constants&previousPath=${encodeURIComponent(location.pathname + location.search)}`} className="flex items-center p-2 text-gray-900 rounded-lggroup">
                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                </svg>
                                <p className="ms-3">Constants</p>
                            </Link>
                        </li>
                    </ul>
                    <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Environment
                        </label>
                        <div className="mt-2">
                            <select
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                onChange={e => {
                                    constants.Environment.host = e.target.value;
                                    console.log(constants.Environment.host);
                                    utils.setCookie("environment", constants.Environment.host);
                                    location.reload();
                                    // ContentRequests.getContent(data => setContent(data));
                                }}
                                value={constants.Environment.host}
                            >
                                <option value={"http://localhost:8000"}>Local</option>
                                <option value={constants.DEVELOPMENT_HOST}>Development</option>
                                <option value={constants.PRODUCTION_HOST}>Production</option>
                            </select>
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <Link to={`/userSettings`} className="flex items-center p-2 text-gray-900 rounded-lggroup">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                            </svg>
                            <p className="ms-3">User Settings</p>
                        </Link>
                    </div>
                    <div className="sm:col-span-3">
                        <Link to={`/migrateEnvironment`} className="flex items-center p-2 text-gray-900 rounded-lggroup">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                <ellipse cx="10" cy="4.99982" rx="8" ry="3" stroke="currentColor" strokeWidth="1.5" />
                                <path d="M5 10.8419C5.60158 11.0227 6.27434 11.1716 7 11.2818" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M5 17.8419C5.60158 18.0227 6.27434 18.1716 7 18.2818" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M12 15.4996L13.1363 16.965C13.708 14.8316 15.9014 13.5656 18.0352 14.1373C19.1275 14.4299 19.9925 15.1473 20.5 16.064M22 20.4984L20.8637 19.035C20.2919 21.1684 18.0986 22.4344 15.9647 21.8627C14.8978 21.5769 14.0477 20.8858 13.5359 19.9995" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 4.99982V11.008M2 4.99982V19.019C2 20.5509 5.05369 21.815 9 21.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2 11.9998C2 13.5416 5.05369 14.8138 9 14.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                            <p className="ms-3">Migrate Environment</p>
                        </Link>
                    </div>
                </div>
            </aside>

            <div className="body-container">
                <Outlet />
            </div>
        </>
    );
}

export default Layout;